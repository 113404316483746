import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/common/Layout';
import MyAppListing from '../../components/AppListing';
import Container from '../../components/common/Container';

const AppListingPage = () => (
  <Layout lang="en">
    <SEO
      title="Which apps and versions of Nextcloud are available at Portknox?"
      description="The installable Nextcloud apps and their versions are always listed here."
    />
    <Container>
      <MyAppListing />
    </Container>
  </Layout>
);

export default AppListingPage;
